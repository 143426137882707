@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'), url('../assets/fonts/Oswald-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald Medium'), local('Oswald-Medium'), url('../assets/fonts/Oswald-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 800;
  src: local('Oswald Bold'), local('Oswald-Bold'), url('../assets/fonts/Oswald-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url('../assets/fonts/Lato-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  src: local('Lato Regular'), local('Lato-Regular'), url('../assets/fonts/Lato-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  src: local('Lato Bold'), local('Lato-Bold'), url('../assets/fonts/Lato-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}
