@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.personalContribution {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  & div:last-child {
    padding-left: 30px;
  }
}
.disclaimer {
  margin-top: 24px;
}

.totalCosts {
  h2 {
    margin: 0;
  }
}
