@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.ctaSection {
  box-shadow: 0 10px 20px rgba(50, 50, 93, 0.1), 0 2px 15px rgba(0, 0, 0, 0.07);
  text-align: center;
  padding: 20px;
  margin-bottom: 24px;
  border-radius: 16px;
  h2 {
    margin-top: 0;
  }
  button {
    min-width: 250px;
  }
}
