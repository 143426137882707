.container {
  height: 100%;
  position: relative;
  .inner {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    svg {
      max-width: 400px;
    }
  }
  .form {
    .termsOfUseConsent div div {
      justify-content: center;
    }
    .btnWrapper {
      text-align: center;
      button {
        min-width: 200px;
      }
    }
  }
}
