@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.tabs {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  position: sticky;
  flex-wrap: wrap;
  top: 0;
  z-index: 1;
  background-color: white;
  margin-bottom: 16px;
  border-radius: 0 0 6px 6px;
  @include breakpoint-up(md) {
    background-color: transparent;
  }
  .tab {
    font-weight: 300;
    border-radius: 0 !important;
    &:first-child {
      padding-left: 5px;
      border-bottom-left-radius: 6px;
      @include breakpoint-up(md) {
        border-top-left-radius: 6px;
      }
    }
    &:last-child {
      padding-right: 5px;
      border-bottom-right-radius: 6px;
      @include breakpoint-up(md) {
        border-top-right-radius: 6px;
      }
    }
    flex-grow: 1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    font-size: 15px;
    //reset standard button styles
    border-width: initial;
    border-style: none;
    border-image: initial;
    outline: 0px;
    background-color: white;
    padding: 15px 0;
    @include breakpoint-up(md) {
      padding: 15px 5px;
      border-bottom: 2px solid $light-gray-color;
      margin: 0;
    }
    @include breakpoint-up(lg) {
      padding: 15px 1px;
    }
    @include breakpoint-up(xl) {
      padding: 22px 5px;
    }
    .circle {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $light-gray-color;
      .step {
        display: none;
      }
      @include breakpoint-up(md) {
        width: 24px;
        height: 24px;
        border: 1px solid $light-gray-color;
        background-color: transparent;
        .step {
          display: inline;
        }
      }
      @include breakpoint-up(lg) {
        font-size: 12px;
        width: 22px;
        height: 22px;
      }
      @include breakpoint-up(xl) {
        font-size: 14px;
        width: 24px;
        height: 24px;
      }
    }
    .title {
      display: none;
      @include breakpoint-up(lg) {
        display: inline-block;
        margin-left: 6px;
        color: $dark-gray-color;
      }
    }
  }
  .activeTab {
    max-width: 55%;
    padding-top: 0;
    padding-bottom: 3px;
    @include breakpoint-up(md) {
      padding: 15px 5px;
    }
    @include breakpoint-up(lg) {
      padding: 15px 1px;
    }
    @include breakpoint-up(xl) {
      padding: 22px 5px;
    }
    @include breakpoint-up(lg) {
      max-width: initial;
    }
    .circle {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border: 1px solid $primary-color;
      background-color: transparent;
      .step {
        display: block;
      }
      @include breakpoint-up(lg) {
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
      }
      @include breakpoint-up(xl) {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
      }
    }
    .title {
      color: #000;
    }
    .title {
      text-align: left;
      margin-left: 5px;
      display: block !important;
    }
    @include breakpoint-up(md) {
      border-bottom: 2px solid $primary-color;
    }
  }
  .clickableTab {
    &:hover {
      cursor: pointer;
    }
  }
  .completedTab {
    @include breakpoint-up(md) {
      border-bottom: 2px solid $primary-color;
    }
    .title {
      color: #5f5f5f;
    }
    .circle {
      background-color: $dark-green-color;
      border: none;
      @include breakpoint-up(md) {
        &:after {
          content: '';
          display: inline-block;
          width: 5px;
          height: 10px;
          transform: rotate(45deg);
          border-right: 2px solid white;
          border-bottom: 2px solid white;
          margin-bottom: 3px;
        }
      }
      .step {
        display: none;
      }
    }
  }
  button.completedTab.activeTab {
    .title {
      font-weight: bold;
    }
    .circle {
      &::after {
        content: '';
        display: inline-block;
        width: 5px;
        height: 10px;
        transform: rotate(45deg);
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        margin-bottom: 3px;
      }
    }
  }
}
