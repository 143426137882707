@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.sectionWrapper {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.header {
  background-color: white;
  border-radius: 6px;
  padding: 16px 0;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: $primary-color;
  h3 {
    margin: 0;
    font-size: 16px;
    color: $primary-color;
    @include breakpoint-up(md) {
      font-size: 20px;
    }
  }
  .endIcon {
    margin-left: auto;
    display: flex;
  }
}
