@import 'styles/variables.scss';

.rotate {
  animation: loading 3s linear infinite;
  transform-origin: center;
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(1turn);
    }
  }
}

@keyframes swing {
  0% {
    transform: rotate(1deg);
  }
  25% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
  75% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

.swing {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-name: swing;
  animation-name: swing;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

.checkboxWrapper {
  padding: 20px 0;
  position: relative;
  .checkbox {
    display: flex;
    margin-bottom: 5px;
    align-items: center !important;
    .label {
      color: $primary-color;
      cursor: pointer;
      padding: 0 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 19px;
      display: flex;
      font-weight: 600;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
      line-height: 20px;
      & > div {
        text-align: left;
      }
    }
    .icon {
      margin-top: 5px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .checkboxInput {
      margin-top: 3px;
      & * {
        box-sizing: content-box !important;
      }
    }
  }
  :global(.rc-checkbox-checked) :global(.rc-checkbox-inner) {
    border-color: $primary-color;
    background-color: $primary-color;
  }
}

.highlighted {
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -8px;
    height: 80%;
    width: 2px;
    background-color: $primary-color;
  }
  .checkbox {
    .label {
      font-size: 20px;
      position: relative;
    }
    .icon polygon {
      @extend .rotate;
    }
  }
}

.note {
  text-align: left;
}
