$primary-color: #95c11f;
$primary-light-color: lighten($primary-color, 40);
$danger-color: #f2397b;
$dark-green-color: #7a9e1a;
$yellow-color: #ffe000;
$light-gray-color: #e0e0e0;
$medium-gray-color: #707070;
$gray-color: #575757;
$dark-gray-color: #3c3c3c;
$blue-color: #0086cd;
$text-color: #000;
$text-inverted-color: #fff;

$font-family-heading: 'Oswald', sans-serif;
$font-family: 'Lato', sans-serif;

$navbar-height: 72px;
$sidebar-width: 240px;
$footer-height: 35px;
$footer-mobile-height: 60px;

// Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$z-index-loading-overlay: 10;
$z-index-navbar: 10;
$z-index-footer: 5;
$z-index-modal: 30;
$z-index-sidebar-overlay: 7;
$z-index-sidebar: 8;

$spacer: 16px;

// the :export directive is the magic sauce for webpack
:export {
  primaryColor: $primary-color;
  primaryLightColor: $primary-light-color;
  dangerColor: $danger-color;
  darkGreenColor: $dark-green-color;
  yellowColor: $yellow-color;
  mediumGrayColor: $medium-gray-color;
  grayColor: $gray-color;
  darkGrayColor: $dark-gray-color;
  blueColor: $blue-color;

  navbarHeight: $navbar-height;
}
