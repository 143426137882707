@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.bodyOpen {
  overflow-y: hidden;
}

.primary {
  background-color: #f7f8f9 !important;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  z-index: $z-index-modal;
  padding: 0 20px 20px;
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.modal {
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0;
  outline: none;
  box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03);
  border-radius: 10px;
  position: relative;
  border: none;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  min-height: 200px;
  overflow: hidden;
  padding: 0 10px 30px 20px;
  max-width: 900px;
  width: 100%;
  margin-top: 0;
}

.modalAfterOpen {
  opacity: 1;
}

.modalBeforeClose {
  opacity: 0;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  position: relative;
  padding-right: 40px;
  h1 {
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin: 15px auto;
  }
  @include breakpoint-up(md) {
    padding: 0 40px 0 0;
    h1 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin: 20px 0;
    }
  }
}

.modalCloseButton {
  border: none;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  margin-right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: 10px;
  @include breakpoint-up(md) {
    top: 15px;
    right: 15px;
  }
  svg {
    min-width: 28px;
    min-height: 28px;
    width: 100%;
    height: 100%;
  }
  &:hover {
    background-color: $primary-light-color;
  }
}

.modalBody {
  overflow: auto;
  max-height: 60vh;
  margin-bottom: 15px;
  padding-right: 10px;
}
