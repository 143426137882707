@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.header {
  h1 {
    margin-bottom: 5px;
  }
  p {
    margin-top: 5px;
  }
}

.colForm {
  order: 1;
  @include breakpoint-up(md) {
    order: 0;
  }
  .subTitle {
    text-align: center;
    @include breakpoint-up(md) {
      text-align: left;
    }
  }
}

.totalValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  border-radius: 8px;
  height: 60px;
  padding: 15px;
  span {
    font-size: 16px;
    line-height: 19px;
    color: $dark-gray-color;
  }
  b {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000;
  }
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 6px;
  margin: 15px 0 32px;
  background-color: $medium-gray-color;
}

.imgBox {
  position: sticky;
  top: 120px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.btnWrapper {
  text-align: right;
  button {
    width: 100%;
    @include breakpoint-up(md) {
      width: initial;
    }
  }
}

.immazing {
  text-align: center;
  margin-bottom: 1rem;
  p {
    color: $primary-color;
    font-size: 1.3rem;
  }
  b {
    font-size: 1.4rem;
  }
  .note {
    font-size: 1rem;
  }
}

.row {
  justify-content: space-between;
}

.countersWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionDescription {
  font-size: 16px;
}

.buttonsWrapper {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  button:only-child {
    flex-grow: 1;
  }
  @include breakpoint-up(md) {
    button:only-child {
      flex-grow: 0;
    }
  }
}
