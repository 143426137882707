.table {
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    padding: 10px 15px;
    border: 1px solid $light-gray-color;
  }

  thead tr,
  tfoot tr {
    background-color: #f2f1f1;
    border-radius: 6px;
    font-weight: bold;
    text-transform: uppercase;
  }

  thead tr,
  tfoot tr {
    th,
    td {
      border-spacing: unset;
      color: $text-color;
      text-align: left;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0.4em 0;
    }
  }

  &.primary {
    thead tr {
      background-color: $primary-color;
      th {
        color: $text-inverted-color;
      }
    }
  }

  tfoot tr {
    td {
      font-weight: bold;
    }
  }

  tbody {
    td {
      font-weight: 300;
    }
  }

  &.before-last-col-text-right {
    td:nth-last-child(2) {
      // white-space: nowrap;
      text-align: right;
    }
  }

  &.last-col-text-right {
    td:last-child {
      // white-space: nowrap;
      text-align: right;
    }
  }
}
